<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div v-if="type == 'like_page' && !checkedId" class="col-md-8">
                        <div class="card card-orange mt-2">
                            <div class="card-body py-2">
                                <h6 class="bold font-18 text-center red">
                                    Hướng dẫn kiểm tra ID Fanapge trước khi tăng Like
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 1:</span> Lấy ID Fanapage và nhập vào ô Input đầu tiên
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 2:</span> Lấy 1 tài khoản Facebook bất kỳ và Like trang
                                    Fanpage.
                                </h6>
                                <h6 class="font-14 mb-0">
                                    <span class="bold">Bước 3:</span> Lấy ID tài khoản Facebook vừa Like Fanpage và nhập
                                    vào ô Input thứ 2.
                                </h6>
                                <h6 class="font-14">
                                    <span class="bold">Bước 4:</span> Click Kiểm Tra để kiểm tra Fanpage có thể tăng
                                    Like hay không.
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group mt-3">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">
                                    ID
                                    {{
                                        type == "follow"
                                            ? "trang cá nhân"
                                            : type == "like_page"
                                            ? "Fanpage"
                                            : type == "like_comment"
                                            ? "bình luận"
                                            : "bài viết"
                                    }}
                                    cần tăng Like:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="object_id" type="text" class="form-control input-light" />
                                <div v-if="type == 'like_page'" class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group-lg form-group">
                            <div class="col-md-4 col-12">
                                <h6 class="bold mb-0">ID Facebook đã Like Page:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="facebook_id" type="text" class="form-control input-light" />
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkIDPage()"
                                            class="btn btn-block btn-dark-blue btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Kiểm tra
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <button
                                            @click="checkedId = !checkedId"
                                            class="btn btn-block btn-orange btn-rounded bold mt-3"
                                            type="submit"
                                        >
                                            Bỏ qua
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link hoặc ID
                                    {{
                                        type == "follow"
                                            ? "trang cá nhân"
                                            : type == "like_page"
                                            ? "Fanpage"
                                            : type == "like_comment"
                                            ? "bình luận"
                                            : "bài viết"
                                    }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div
                                    v-if="type == 'like_page' || type == 'follow' || type == 'review'"
                                    class="card card-orange mt-2"
                                >
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="type != 'view'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Loại Seeding cần tăng:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select v-model="type" class="custom-select select-light">
                                    <option v-if="type != 'follow' && type != 'like_page' && type != 'review'" value="share">Chia sẻ bài viết Facebook</option>
                                    <option v-if="type != 'follow' && type != 'like_page' && type != 'review'" value="like">Like bài viết Facebook</option>
                                    <option v-if="type != 'follow' && type != 'like_page' && type != 'review'" value="comment">Bình luận bài viết Facebook</option>
                                    <option v-else-if="$route.path.includes('review')" value="review">Buff Review (Tăng Đánh Giá Fanpage)</option>
                                    <option v-else-if="$route.path.includes('page')" value="like_page">Tăng Like Fanapge</option>
                                    <option v-else-if="$route.path.includes('follow')" value="follow">Tăng Follow - Theo dõi trang cá nhân Facebook</option>
                                </select>
                            </div>
                        </div> -->
                        <div v-if="type == 'like'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chọn cảm xúc:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="fb-reaction text-left">
                                    <div class="checkbox" v-for="reaction in reactions" :key="reaction.name">
                                        <label>
                                            <img
                                                :src="reaction.image"
                                                :class="[
                                                    'img-responsive image-reaction ml-2',
                                                    reactionSelected.indexOf(reaction.name) > -1 ? 'image-active' : ''
                                                ]"
                                                alt="Image"
                                            />
                                            <input type="checkbox" @click="selectReaction(reaction.name)" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="type != 'review' && type != 'view' && type != 'like_comment'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chế độ bảo hành:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <select
                                    v-model="is_warranty"
                                    class="form-control custom-select select-light"
                                    required="required"
                                >
                                    <option :value="false">Không bảo hành (Bảo hành nếu tụt quá 70%)</option>
                                    <option :value="true">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
                                </select>
                                <div v-show="is_warranty" class="row mt-4">
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày bắt đầu</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_start"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="minDatetime"
                                        ></datetime>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày kết thúc</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_expired"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="time_start"
                                            :max-datetime="increaseDateByNumber(time_start, date_warranty)"
                                        ></datetime>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div
                            v-if="configSite.open_target && type != 'view' && type != 'like_comment'"
                            class="row form-group"
                        >
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Lọc nâng cao:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="is_filter = !is_filter"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                is_filter ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Bật
                                        </button>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <button
                                            @click="is_filter = !is_filter"
                                            :class="[
                                                'btn btn-block btn-rounded bold',
                                                !is_filter ? 'btn-filter' : 'btn-light'
                                            ]"
                                            type="submit"
                                        >
                                            Tắt
                                        </button>
                                    </div>
                                </div>
                                <h6 v-show="is_filter" class="mb-0 mt-3 font-14">
                                    Hệ thống sẽ <span class="bold green">thu phí thêm {{ price_target }}%</span> cho
                                    <span class="bold green">1 lựa chọn nâng cao</span>
                                </h6>
                                <div v-show="is_filter" class="card card-gray mt-2">
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Giới tính:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row">
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('male')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == 'male' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Nam
                                                        </button>
                                                    </div>
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('female')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == 'female' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Nữ
                                                        </button>
                                                    </div>
                                                    <div class="col-md-4 mt-2">
                                                        <button
                                                            @click="setGender('')"
                                                            :class="[
                                                                'btn btn-block btn-sm btn-rounded bold',
                                                                gender == '' ? 'btn-filter' : 'btn-light'
                                                            ]"
                                                            type="submit"
                                                        >
                                                            Tất Cả
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Độ tuổi:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row align-items-center">
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                v-model="age.min"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option value="">Không lọc theo tuổi</option>
                                                                <option
                                                                    v-for="(item, index) in 65"
                                                                    :key="index"
                                                                    v-show="item > 12"
                                                                    :value="item"
                                                                >
                                                                    {{ item }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6 class="bold mb-0">đến</h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                :disabled="!age.min"
                                                                v-model="age.max"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option
                                                                    v-for="(item, index) in 65"
                                                                    :key="index"
                                                                    v-show="item > 12"
                                                                    :value="item"
                                                                >
                                                                    {{ item }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-3">
                                            <div class="col-md-4">
                                                <h6 class="bold mb-0">Số bạn bè:</h6>
                                            </div>
                                            <div class="col-md">
                                                <div class="row align-items-center">
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                v-model="friend.min"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option value="">Không lọc bạn bè</option>
                                                                <option
                                                                    v-for="(item, index) in 50"
                                                                    :key="index"
                                                                    :value="item * 100"
                                                                >
                                                                    {{ item * 100 }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <h6 class="bold mb-0">đến</h6>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="input-group">
                                                            <select
                                                                :disabled="!friend.min"
                                                                v-model="friend.max"
                                                                class="form-control custom-select select-light"
                                                            >
                                                                <option
                                                                    v-for="(item, index) in 50"
                                                                    :key="index"
                                                                    :value="item * 100"
                                                                >
                                                                    {{ item * 100 }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div v-if="type != 'comment' && type != 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model="quantity" type="number" class="form-control input-light" />
                                <div v-if="type != 'view'" class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: hệ thống hoạt động theo hình thức order tức là bạn order số lượng bao
                                            nhiêu thì hệ thống sẽ tự động lấy trong cơ sở dữ liệu ra số lượng người
                                            tương ứng để like, follow,... cho bạn.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold">
                                            Nên nếu nick của họ bị khóa (checkpoint) trong lúc tăng sẽ gây tụt trong lúc
                                            buff làm lượng like lên không đủ số lượng cần tăng là bình thường.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, vui lòng buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt hoặc
                                            chọn gói bảo hành để được hoàn tiền nếu tụt. Chúng tôi sẽ không bảo hành với
                                            bất cứ lý do nào.
                                        </h6>
                                    </div>
                                </div>
                                <div v-else class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 text-danger font-bold">
                                            Lưu ý: Hiện tại Facebook quét hệ thống tăng View và Mắt rất mạnh nên hệ
                                            thống này không ổn đinh, có thể không lên hoặc lên chậm.
                                        </h6>
                                        <h6 class="font-13 text-danger font-bold mb-0">
                                            Do đó, hệ thống <span class="bold">KHÔNG BẢO HÀNH</span> buff VIEW và MẮT,
                                            vui lòng cài đặt số lượng MIN để test trước để tránh không tăng được mất
                                            tiền !
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="type == 'view'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chọn loại View:</h6>
                            </div>
                            <div class="col-md col-12">
                                <select class="custom-select select-light">
                                    <option value="s">Tăng View Video Thường</option>
                                    <!-- <option value="p">Tăng View Video 1 Phút</option>
                                    <option value="3p">Tăng View Video 3 Phút</option>
                                    <option value="10p">Tăng View Video 10 Phút</option>
                                    <option value="30p">Tăng View Video 30 Phút</option>
                                    <option value="ls">Tăng View Video LiveSteam</option> -->
                                </select>
                            </div>
                        </div>
                        <div v-if="type != 'view'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light"
                                />
                                <div class="card card-white mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="type === 'comment' || type === 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Nội dung cần comment:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-13">
                                            Nghiêm cấm {{ type == "comment" ? "bình luận" : "Review" }} các nội dung có
                                            biểu tượng Icon.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13">
                                            Nghiêm cấm {{ type == "comment" ? "bình luận" : "Review" }} những nội có cử
                                            chỉ, lời nói thô bạo, khiêu khích, trêu ghẹo, xúc phạm nhân phẩm, danh dự
                                            của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                        <h6 v-if="type == 'review'" class="text-danger bold font-13">
                                            Hãy chắc chắn Fanpage được buff Review đã mở phần đánh giá, nếu Fanpage chưa
                                            mở đánh giá và bạn cố tình cho vào hệ thống để buff sẽ không được hoàn tiền.
                                        </h6>
                                        <h6 class="text-danger font-bold font-13 mb-0">
                                            Các ngôn từ bị cấm: dm|đm|đ m|d m|địt mẹ|dit
                                            me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua
                                            dao|con cho|tra tien|mat day
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung {{ type == "comment" ? "bình luận" : "Review" }} (<label
                                            class="font-bold"
                                            >Mỗi dòng tương đương với 1
                                            {{ type == "comment" ? "bình luận" : "Review" }}</label
                                        >):</label
                                    >
                                    <span class="badge badge-primary text-white py-1">Số lượng: {{ quantity }}</span>
                                    <h6 v-if="type == 'review'">
                                        <span class="badge badge-danger text-white py-2"
                                            >Lưu ý: Nội dung của một review tối thiểu phải 25 ký tự</span
                                        >
                                    </h6>
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 tương ứng 1 nội dung"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff
                                            <span class="bold"
                                                >{{ quantity }} {{ type === "like" ? type_reaction : type }}</span
                                            >
                                            với giá <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ type === "like" ? type_reaction : type }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="type != 'view'" class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn <b class="font-bold">trong vòng 1 đến 3 ngày</b>
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id bài viết, công khai và check kỹ job tránh tạo nhầm, tính năng
                                    đang trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p v-if="type == 'like_page'" class="font-bold">
                                    - Không tăng Like cho Page có thời gian tạo dưới 1 tháng.
                                </p>
                                <p v-else>
                                    - Chỉ nhận id bài viết công khai không nhập id album, id comment, id trang cá nhân,
                                    id page,...
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    1k trong ngày (hoặc hơn nếu order giá cao), trên 1k thời gian lên chậm hơn trong
                                    vòng 7 ngày
                                </p>
                            </div>
                        </div>
                        <div v-else class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <strong
                                    ><b><i class="mr-1"></i>Hướng dẫn: </b></strong
                                >
                                <br />- Số lượng view tối thiểu mỗi lần mua là 500 view , tối đa là 100,000 View.
                                <br />- Hệ thống tăng View và hỗ trợ tăng mắt LiveStream trong quá trình chạy View.
                                <br />- Để giống người xem thật, số lượng View sẽ được điều chỉnh lên xuống ngẫu nhiên
                                quanh số View của bạn mua.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <facebooklogs :panel="panel" :type="type"></facebooklogs>
            </div>
        </div>
    </div>
</template>
<script>
import { facebookPostUrlToId, getUserIdFacebookFromUrl, catchError } from "../../../../helpers"
import { convertUsernameToFbId, orderSeedingJob, checkLikePage } from "../../../../api/services/facebook"
import facebooklogs from "./facebook-logs"

export default {
    name: "facebook-order",
    components: { facebooklogs },
    data() {
        return {
            facebook_id: "",
            checkedId: true,
            object_id: "",
            type: "",
            reactionSelected: [],
            type_reaction: "like",
            reactions: [
                {
                    name: "like",
                    image: "assets/images/fb-reaction/like.png"
                },
                {
                    name: "care",
                    image: "assets/images/fb-reaction/care.png"
                },
                {
                    name: "love",
                    image: "assets/images/fb-reaction/love.png"
                },
                {
                    name: "haha",
                    image: "assets/images/fb-reaction/haha.png"
                },
                {
                    name: "wow",
                    image: "assets/images/fb-reaction/wow.png"
                },
                {
                    name: "sad",
                    image: "assets/images/fb-reaction/sad.png"
                },
                {
                    name: "angry",
                    image: "assets/images/fb-reaction/angry.svg"
                }
            ],
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            gender: "",
            age: {
                min: 0,
                max: 0
            },
            friend: {
                min: 0,
                max: 0
            },
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 10,
            price_target: 0,
            price_default: 100,
            notes: "",
            list_messages: ""
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        configSite() {
            return this.$store.state.site.site.config || false
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            let getPackage = "seeding_by_workers_query_config"
            if (this.type === "follow" || this.type === "like_page") {
                getPackage = "seeding_by_workers_query_" + this.type + "_config"
            }
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            if (this.is_filter) {
                let priceTarget = tool.prices.find(price => price.package_name.includes(getPackage))
                let priceTargets = priceTarget ? priceTarget.price : 50
                if (this.type === "follow" || this.type === "like_page") {
                    priceTargets = priceTargets < 100 ? 100 : priceTargets
                } else {
                    priceTargets = priceTargets < 20 ? 20 : priceTargets
                }
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.price_target = priceTargets
                if (this.gender.toString() !== "") {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.age.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
                if (this.friend.min > 0) {
                    price += (parseInt(this.price) * parseInt(priceTargets)) / 100
                }
            }
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        router(value) {
            this.returnType(value)
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        async object_id() {
            if (this.object_id.indexOf("facebook.com") > -1) {
                if (this.type === "follow" || this.type === "like_page" || this.type === "review") {
                    this.object_id = getUserIdFacebookFromUrl(this.object_id)
                } else this.object_id = facebookPostUrlToId(this.object_id)
            }
        },
        type(value) {
            this.getPriceSeedingType(value)
        },
        type_reaction(value) {
            if (this.type === "like") {
                let getPackage = "seeding_by_workers_" + (this.is_warranty ? value + "_warranty" : value)
                let price = this.prices.find(price => price.package_name === getPackage)
                if (price) {
                    this.price = price.price
                    this.price_default = price.price
                }
            }
        },
        is_warranty(value) {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 69)
            let prices = tool.prices
            let type = this.type === "like" ? this.type_reaction : this.type
            let getPackage = "seeding_by_workers_" + (value ? type + "_warranty" : type)
            let price = prices.find(price => price.package_name === getPackage)
            if (price) {
                this.price = price.price
                this.price_default = price.price
            }
        }
    },
    async created() {
        this.returnType(this.router)
        this.getPriceSeedingType(this.type)
        this.is_warranty = false
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
    },
    methods: {
        returnType(value) {
            if (value.includes("page")) {
                this.type = "like_page"
            } else if (value.includes("follow")) {
                this.type = "follow"
            } else if (value.includes("like-comment")) {
                this.type = "like_comment"
            } else if (value.includes("comment")) {
                this.type = "comment"
            } else if (value.includes("share")) {
                this.type = "share"
            } else if (value.includes("review")) {
                this.type = "review"
            } else if (value.includes("view")) {
                this.type = "view"
                this.quantity = 500
            } else {
                this.type = "like"
                this.type_reaction = "like"
                this.selectReaction(this.type_reaction)
            }
            this.is_warranty = false
        },
        async checkIDPage() {
            if (!this.object_id || !this.facebook_id) {
                this.$swal("Thông báo", "Hãy làm đúng yêu cầu các bước trước khi kiểm tra!", "error")
                return
            }
            let postData = {
                object_id: this.object_id,
                fb_id: this.facebook_id
            }
            let data = await checkLikePage(postData)
            if (data.status === 200 && data.success) {
                this.checkedId = true
                await this.$swal("Thông báo", "Fanpage có thể tăng Like, hãy thực hiện các bước tiếp theo", "success")
            } else {
                await this.$swal("Thông báo", catchError(data), "error")
            }
        },
        order: async function() {
            if (this.price >= 2 * this.price_default) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }
            if ((this.type === "comment" || this.type === "review") && this.list_messages.length < 1) {
                this.$toastr.error(`Vui lòng nhập nội dung cần ${this.type === "comment" ? "bình luận" : "Review"}`)
                return
            }
            if (this.type === "comment" || this.type === "review") {
                let checkBackList = this.list_messages
                    .toLowerCase()
                    .match(
                        /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                    )
                if (this.list_messages.length > 1 && checkBackList && checkBackList.length > 0) {
                    this.$swal(
                        "Lỗi",
                        `${this.type === "comment" ? "Bình luận" : "Review"} của bạn có chứa nội dung [${
                            checkBackList[0]
                        }] vi phạm tiêu chuẩn cộng đồng, hãy chỉnh sửa lại nội dung cần bình luận!`,
                        "error"
                    )
                    return
                }
                let arrayMessages = this.list_messages.match(/.+/g)
                // let hasDuplicate = arrayMessages.some((val, i) => arrayMessages.indexOf(val) !== i)
                // if (hasDuplicate) {
                //     this.$swal("Lỗi", `Nội dung ${this.type === "comment" ? "bình luận" : "Review"} không được trùng nhau!`, "error")
                //     return
                // }
                for (let index = 0; index < arrayMessages.length; index++) {
                    if (arrayMessages[index].length < 25 && this.type === "review") {
                        this.$swal("Lỗi", "Nội dung Review thứ " + (index + 1) + " tối thiểu phải 25 ký tự!", "error")
                        return
                    } else {
                        let checkIcon = arrayMessages[index].match(/[|\\/~^:;?!&%$@*+]/)
                        if (checkIcon) {
                            this.$swal({
                                title: "Lỗi",
                                html: `Nội dung ${this.type === "comment" ? "bình luận" : "Review"} thứ ${index +
                                    1} không được chứa ký tự đặc biệt: <span class="red bold">${checkIcon[0]}</span>`,
                                type: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "OK"
                            })
                            return
                        }
                    }
                }
            }
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${
                    this.type === "like" ? this.type_reaction.toUpperCase() : this.type.toUpperCase()
                } với giá: ${this.priceSingle} ${this.currency}/1 ${
                    this.type === "like" ? this.type_reaction.toUpperCase() : this.type.toUpperCase()
                } ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link: "https://www.facebook.com/" + this.object_id,
                type: this.type === "like" ? this.reactionSelected.toString() : this.type,
                object_id: this.object_id,
                quantity: this.quantity,
                prices: this.price * this.quantity,
                time_expired: this.time_expired,
                is_warranty: this.is_warranty,
                notes: this.notes
            }
            if (this.gender && this.is_filter) {
                postData.gender = this.gender
            }
            if (this.age.min > 0 && this.is_filter) {
                if (this.age.min > this.age.max) {
                    this.$swal("Lỗi", "Số tuổi bắt đầu phải nhỏ hơn số tuổi kết thúc", "error")
                    return
                }
                postData.age_min = this.age.min
                postData.age_max = this.age.max
            }
            if (this.friend.min > 0 && this.is_filter) {
                if (this.friend.min > this.friend.max) {
                    this.$swal("Lỗi", "Số bạn bè bắt đầu phải nhỏ hơn số bạn bè kết thúc", "error")
                    return
                }
                postData.friend_min = this.friend.min
                postData.friend_max = this.friend.max
            }
            if (this.type === "review" || this.type === "comment") {
                postData.list_messages = this.list_messages.match(/.+/g)
            }
            let data = await orderSeedingJob(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
            // this.$store.dispatch("USER_GET_INFO")
        },
        getPriceSeedingType: function(type) {
            let price = this.prices.find(price => price.package_name == "seeding_by_workers_" + type)
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        setGender: function(gender) {
            this.gender = gender
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },
        selectReaction: function(name) {
            this.reactionSelected = new Array()
            this.reactionSelected.push(name)
            this.type_reaction = name
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = regex[1]
                        return true
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = data.data.id
                        return true
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
